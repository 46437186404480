import React from 'react';
import { Container, Typography } from '@mui/material';

const Dashboard = () => (
  <Container maxWidth="lg">
    <Typography variant="h1" component="h1" gutterBottom>
      
    </Typography>
    {/* Contenido del dashboard */}
  </Container>
);

export default Dashboard;
